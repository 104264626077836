import { useState } from 'react';
import { supabase } from 'src/utils/supabaseClient';

export default function Import() {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('Idle');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();


        const formData = new FormData(e.target as HTMLFormElement);
        const file = formData.get('file') as File;

        const reader = new FileReader();
        reader.onload = () => {
            const data = reader.result as string;
            // console.log(data);

            const geojson = JSON.parse(data);

            insertFeatures(geojson.features);
        };
        reader.readAsText(file);
    }


    const insertFeatures = async (features) => {      
        const BATCH_SIZE = 100; 

        // Transform features to match database schema
        const transformedFeatures = features.map(feature => ({
          type: 'parish',
          label: feature.properties.name,
          // Convert GeoJSON geometry to GeoJSON string or WKT if preferred
          geojson: JSON.stringify(feature.geometry.coordinates),
        }));
      
        const totalBatches = Math.ceil(transformedFeatures.length / BATCH_SIZE);
        const featureBatches = [];

        for (let i = 0; i < transformedFeatures.length; i += BATCH_SIZE) {
          featureBatches.push(transformedFeatures.slice(i, i + BATCH_SIZE));
        }
      
        for (let i = 0; i < featureBatches.length; i++) {
          setStatus(`Inserting batch ${i + 1} of ${totalBatches}...`);
          const batch = featureBatches[i];

          const { data, error } = await supabase
            .from('regions')
            .upsert(batch);
      
          if (error) {
            console.error('Error inserting batch:', error);
            // Handle errors as needed (e.g., retry, skip, etc.)
          } else {
            setProgress(((i + 1) / totalBatches) * 100);
          }
        }
      
        console.log('All features processed.');
    };


    return (
        <div className="p-4">
            <h1>Import</h1>
            <form onSubmit={handleSubmit}>
                
                <input 
                    type="file" 
                    name="file"
                />

                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-lg">Submit</button>
            </form>

            <div style={{ marginTop: '20px' }}>
              <div style={{ width: '100%', backgroundColor: '#ccc', height: '25px', borderRadius: '5px' }}>
                <div
                  style={{
                    width: `${progress}%`,
                    backgroundColor: '#4caf50',
                    height: '100%',
                    borderRadius: '5px',
                    transition: 'width 0.5s'
                  }}
                ></div>
              </div>
              <p>{Math.round(progress)}% - {status}</p>
            </div>
        </div>
    );
}