import { Types } from "src/types";
import { formatDate } from "src/utils/utils";
import attributeTypeData from 'src/config/attributeTypes.json';
const attributeTypes: Types.AttributeTypeConfig = attributeTypeData;
interface AttributeListItemProps {
    attr: any;
}

export default function AttributeListItem({ attr }: AttributeListItemProps) {
    if (!attr.name || attr.name === 'notes') return null; // we won't separate notes from audits for now

    const attrConfig = attributeTypes[attr.name];

    const getUnit = () => {
        return attrConfig?.unit || '';
    }
    const getEstimated = (attr: Types.Attribute) => {
        return attr?.estimated ? ' (estimated)' : '';
    }

    const prepareValue = () => {
        if (attrConfig.type === 'date') return formatDate(new Date(attr.value));

        if (attrConfig.type === 'checkboxes') { 
            const vals = JSON.parse(attr.value);
            
            // capitalize the first letter of each value
            return vals.map((val) => val.charAt(0).toUpperCase() + val.slice(1)).join(', ');
        }

        return attr.value.replaceAll('-', ' ') + getUnit() + getEstimated(attr);
    }

    if (attr.name === 'notes') return (
        <div className="py-1 px-3 col-span-3">
            { attr.value }
        </div>
    );

    return (
        <>
            <div scope="row" className="capitalize py-1 px-3 font-medium text-gray-900 whitespace-nowrap">
                { attr.name.replaceAll('-', ' ') }
            </div>

            <div className="grow py-1 first-letter:uppercase col-span-2 pr-2">
                { prepareValue() }
            </div>
        </>
    );
}