import { useState } from 'react';
import { useLocationsStore } from 'src/stores/locationStore';
import useAuth from 'src/hooks/auth';
import SlSwitch from '@shoelace-style/shoelace/dist/react/switch';

type WatcherProps = {
    watching: boolean;
    location_id: number;
}

export default function Watcher({ watching, location_id }: WatcherProps) {
    const [saving, setSaving] = useState(false);
    const user: User | null = useAuth();
    const { toggleWatch } = useLocationsStore((state) => state);

    const handleWatchClick = () => {
        if (!location_id || !user.user?.id) return;
        setSaving(true);
        
        ( async () => { 
            await toggleWatch(location_id, user.user.id); 
            setSaving(false);
        })();
    };

    if (!user) return null;

    return (
        <div className="relative top-1.5 text-xs text-gray-600">
            <SlSwitch 
                onSlInput={ handleWatchClick }
                checked={ watching }
            >
                { watching ? 'Watching' : 'Not watching' }
            </SlSwitch>

            { saving && <span className="ml-2 text-xs text-gray-600">Saving...</span> }
        </div>
    );
}